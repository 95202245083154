<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-wifi-marker"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Beacons x Equipamentos</div>
      </template>
      <v-btn
        v-if="accessReleased('BEACONS_X_EQUIPAMENTOS_ADICIONAR')"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push('add')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Novo Beacon
      </v-btn>
      <v-btn
        v-if="accessReleased('IMPORTACOES_DE_DADOS_ADICIONAR')"
        color="success"
        dark
        elevation="1"
        class="ml-2 mt-5"
        style="float: left"
        relative
        text
        medium
        @click="importarBeacons"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-database-arrow-up
        </v-icon>
        Importar Beacons
      </v-btn>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />
      <v-divider class="mt-10" />
      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhum beacon encontrado"
      >
        <template v-slot:[`item.mac_address`]="{ item }">
          {{ item.mac_address | toUpperCase }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray"
                min-width="0"
                class="px-1 ml-n1"
                fab
                icon
                x-small
                @click="$router.push(`visualizar-mapa/${item.id}`)"
              >
                <v-icon small> mdi-eye-outline </v-icon>
              </v-btn>
              <v-btn
                id="action-more"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openDialogDelete(item)">
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Deletar </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDialogCreationLog(item)">
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title">
        Deletar Beacon #{{ dialogDeleteData.id }}
      </template>
      <template slot="body">
        Tem certeza que deseja deletar o beacon
        <strong> #{{ dialogDeleteData.id }} </strong>?
      </template>
    </dialog-delete>
  </v-container>
</template>

<script>
import BeaconsService from '@/services/monitoramento-em-tempo-real/BeaconsService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';
import routes from '@/store/modules/routes';
export default {
  name: 'BeaconsXEquipamentosDataTable',
  mixins: [powerupsActionsMixins, profilePermissionsMixin],
  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    DialogDelete: () => import('@/components/general/DialogDelete')
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'MAC Address do Beacon',
        value: 'mac_address'
      },
      {
        text: 'Subestação',
        value: 'sub'
      },
      {
        text: 'Equipamento na BDGD',
        value: 'entidade'
      },
      {
        text: 'COD_ID na BDGD',
        value: 'cod_id'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ],
    search: '',
    dialogDelete: false,
    dialogDeleteData: { id: null }
  }),
  methods: {
    openDialogCreationLog(item) {
      this.dialogCreationLog = true;
      this.dialogCreationLogData = {
        username: item.usuario,
        created: item.created
      };
    },
    openDialogDelete(item) {
      this.dialogDelete = true;
      this.dialogDeleteData = {
        id: item.id
      };
    },
    deletarItem(id) {
      BeaconsService.excluiBeacon(id)
        .then(() => {
          this.$toast.success('Beacon removido com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar o beacon.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogDelete = false;
    },
    importarBeacons() {
      this.$router.replace({
        name: routes.state.appRoutes['IMPORTACOES_DE_DADOS_ADICIONAR']
      });
    }
  }
};
</script>
