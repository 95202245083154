<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <beacons-x-equipamentos-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getBeacons"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BeaconsService from '@/services/monitoramento-em-tempo-real/BeaconsService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import BeaconsXEquipamentosDataTable from '@/components/monitoramento-em-tempo-real/BeaconsXEquipamentosDataTable';
export default {
  mixins: [refreshDataMixins],
  components: {
    BeaconsXEquipamentosDataTable
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getBeacons);
  },
  methods: {
    getBeacons() {
      this.loading = true;
      BeaconsService.getBeacons()
        .then(({ data }) => (this.items = data.data))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
